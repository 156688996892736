import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
//import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
//import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageThanks from "containers/PageAbout/PageThanks";
import PageEnquiry from "containers/PageAbout/PageEnquiry";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageAddListings1 from "containers/PageAddListing2/PageAddListings1";
import PageAddListings2 from "containers/PageAddListing2/PageAddListings2";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PageHome3 from "containers/PageHome/PageHome3";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
//import ItineraryPage from "containers/ListingDetailPage/listing-stay-detail/ItineraryPage";
import ListingCarDetailPage from "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import PageMobileLogin from "containers/PageLogin/PageMobileLogin";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "components/PrivacyPolicy/TermsAndConditions";
import Cancellations from "components/PrivacyPolicy/Cancellations";
//import DubaiHome from "landing/DubaiHome/DubaiHome";
import BaliHome from "landing/BaliHome/BaliHome";
import Hotel1 from "landing/MaldivesHome/Hotel1"
import Hotel2 from "landing/MaldivesHome/Hotel2";
import Pagecomingsoon from "containers/ListingRealEstatePage/Pagecomingsoon";
//import MalaysiaHome from "landing/MalaysiaHome/MalaysiaHome";
//import ThailandHome from "landing/ThailandHome/ThailandHome";
//import CambodiaHome from "landing/CambodiaHome/CambodiaHome";
//import VietnamHome from "landing/VietnamHome/VietnamHome";
//import PhilippinesHome from "landing/PhilippinesHome/PhilippinesHome";
//import ItineraryMobile from "containers/ListingDetailPage/listing-stay-detail/ItineraryMobile";
//import MaldivesHome from "landing/MaldivesHome/MaldivesHome";


// import Visa from "landing/VisaHome/Visa";
//import SingaporeHome from "landing/SingaporeHome/SingaporeHome";
//import AustraliaHome from "landing/AustraliaHome/AustraliaHome";
//import TurkeyHome from "landing/TurkeyHome/TurkeyHome";
//import NewzealandHome from "landing/NewzelandHome/NewzealandHome";
//import Dubai5N6D from "containers/ListingDetailPage/listing-stay-detail/Dubai5N6D";
//import Dubai6N7D from "containers/ListingDetailPage/listing-stay-detail/Dubai6N7D";
//import DubaiLuxury5N6D from "containers/ListingDetailPage/listing-stay-detail/DubaiLuxury5N6D";
//import DubaiLuxury6N7D from "containers/ListingDetailPage/listing-stay-detail/DubaiLuxury6N7D";
//import DubaiLuxury7N8D from "containers/ListingDetailPage/listing-stay-detail/DubaiLuxury7N8D";
//import DubaiPremium6N7D from "containers/ListingDetailPage/listing-stay-detail/DubaiPremium6N7D";
//import DubaiPremiumYas from "containers/ListingDetailPage/listing-stay-detail/DubaiPremiumYas";
//import DubaiPremiumLapita from "containers/ListingDetailPage/listing-stay-detail/DubaiPremiumLapita";


//Bali 
import Bali4N5D from "containers/ListingDetailPage/listing-stay-detail/Bali4N5D";
import Bali5N6D from "containers/ListingDetailPage/listing-stay-detail/Bali5N6D";
import Bali6N7D from "containers/ListingDetailPage/listing-stay-detail/Bali6N7D";
import Bali6Nightss7Dayss from "containers/ListingDetailPage/listing-stay-detail/Bali6Nightss7Dayss";
import Bali7Nights8Days from "containers/ListingDetailPage/listing-stay-detail/Bali7Nights8Days";
import Bali7Nightss8Dayss from "containers/ListingDetailPage/listing-stay-detail/Bali7Nightss8Dayss";
import Bali7Night8Day from "containers/ListingDetailPage/listing-stay-detail/Bali7Night8Day";
import Bali7N8D from "containers/ListingDetailPage/listing-stay-detail/Bali7N8D";
import Bali10N11D from "containers/ListingDetailPage/listing-stay-detail/Bali10N11D";
import DubaiBlogg from "containers/BlogPage/DubaiBlogg";
import BlogDubai from "containers/BlogPage/BlogDubai";
import BlogDubaii from "containers/BlogPage/BlogDubaii";
import PagePopup from "containers/PageHome/PagePopup";
import BlogBali from "containers/BlogPage/BlogBali";
import DubaiVisa from "containers/ListingDetailPage/listing-stay-detail/DubaiVisa";
import SingaporeVisa from "containers/ListingDetailPage/listing-stay-detail/SingaporeVisa";
import MalaysiaVisa from "containers/ListingDetailPage/listing-stay-detail/MalaysiaVisa";
import BlogMalaysia from "containers/BlogPage/BlogMalaysia";
import BlogSingapore from "containers/BlogPage/BlogSingapore";
import BlogSrilanka from "containers/BlogPage/BlogSrilanka";
import Blog from "containers/BlogPage/Blog";
import PageAboutt from "containers/PageAbout/PageAboutt";
import BlogVietnam from "containers/BlogPage/BlogVietnam";
import BlogCambodia from "containers/BlogPage/BlogCambodia";
import Wizard from "containers/CustomizePage/Wizard";
import HotelFilter from "containers/ListingRealEstatePage/HotelFilter";
import HotelDetailPage from "containers/ListingDetailPage/listing-stay-detail/HotelDetailPage"
import Packages from "containers/ListingDetailPage/listing-stay-detail/Packages";
import LandingHome from "landing/LandingHome/LandingHome";
import BlogThailand from "containers/BlogPage/BlogThailand";
import DubaiBlog from "containers/BlogPage/DubaiBlog";
import Dynamic_without_checkout from "containers/ListingDetailPage/listing-stay-detail/Dynamic_without_checkout";
import Dynamic_with_checkout from "containers/ListingDetailPage/listing-stay-detail/Dynamic_with_checkout";
import MauritiusHome from "../landing/MauritiusHome/MauritiusHome";
import MaldivesHome from "landing/MaldivesHome/MaldivesHome";

import LoadingSpinner from "./LoadingSpinner";
import GroupTour from "landing/GroupTour/GroupTour";

import BlogSingaporee from "containers/BlogPage/BlogSingaporee";
import BlogMalaysiaa from "containers/BlogPage/BlogMalaysiaa";

import BlogNepal from "containers/BlogPage/BlogNepal";
import BlogPhilippines from "containers/BlogPage/BlogPhilippines";
import BlogGreece from "containers/BlogPage/BlogGreece";
import BlogEurope from "containers/BlogPage/BlogEurope";


import HotelHome from "landing/HotelHome/HotelHome";
import FlightHome from "landing/FlightHome/FlightHome";
import CheckoutStatus from "containers/CheckOutPage/CheckoutStatus";
import CheckoutSuccess from "containers/CheckOutPage/CheckoutSuccess";
import CheckoutFailed from "containers/CheckOutPage/CheckoutFailed";

//Cambodia 
//import Cambodia3N4D from "containers/ListingDetailPage/listing-stay-detail/Cambodia3N4D";
//import Cambodia4N5D from "containers/ListingDetailPage/listing-stay-detail/Cambodia4N5D";
//import Cambodia5N6D from "containers/ListingDetailPage/listing-stay-detail/Cambodia5N6D";

{/*

//Malaysia 
import Malaysia3N4D from "containers/ListingDetailPage/listing-stay-detail/Malaysia3N4D";
import Malaysia7N8D from "containers/ListingDetailPage/listing-stay-detail/Malaysia7N8D";
import Malaysia5N6D from "containers/ListingDetailPage/listing-stay-detail/Malaysia5N6D";



}
//Vietnam 
import Vietnam3N4D from "containers/ListingDetailPage/listing-stay-detail/Vietnam3N4D";
import Vietnam4N5D from "containers/ListingDetailPage/listing-stay-detail/Vietnam4N5D";
import Vietnam5N6D from "containers/ListingDetailPage/listing-stay-detail/Vietnam5N6D";
import Vietnam5Nights6Days from "containers/ListingDetailPage/listing-stay-detail/Vietnam5Nights6Days";
import Vietnam9N10D from "containers/ListingDetailPage/listing-stay-detail/Vietnam9N10D";
import VietnamCambodia8N9D from "containers/ListingDetailPage/listing-stay-detail/VietnamCambodia8N9D";
import VietnamCambodia12N13D from "containers/ListingDetailPage/listing-stay-detail/VietnamCambodia12N13D";


//Vietnam 
import Thailand4N5D from "containers/ListingDetailPage/listing-stay-detail/Thailand4N5D";
import Thailand6N7D from "containers/ListingDetailPage/listing-stay-detail/Thailand6N7D";
import Thailand10N11D from "containers/ListingDetailPage/listing-stay-detail/Thailand10N11D";
import Thailand3N4D from "containers/ListingDetailPage/listing-stay-detail/Thailand3N4D";
import Thailand6Nights7Days from "containers/ListingDetailPage/listing-stay-detail/Thailand6Nights7Days";
import Thailand8N9D from "containers/ListingDetailPage/listing-stay-detail/Thailand8N9D";
import Thailand4Night5Day from "containers/ListingDetailPage/listing-stay-detail/Thailand4Night5Day";
import Thailand10Nights11Days from "containers/ListingDetailPage/listing-stay-detail/Thailand10Nights11Days";
import Thailand5Day from "containers/ListingDetailPage/listing-stay-detail/Thailand5Day";
import Thailand4Nights5Dayss from "containers/ListingDetailPage/listing-stay-detail/Thailand4Nights5Dayss";


//Singapore 
import Singapore3N4D from "containers/ListingDetailPage/listing-stay-detail/Singapore3N4D";
import Singapore4N5D from "containers/ListingDetailPage/listing-stay-detail/Singapore4N5D";
import Singapore6N7D from "containers/ListingDetailPage/listing-stay-detail/Singapore6N7D";
import Singapore6Nights7Days from "containers/ListingDetailPage/listing-stay-detail/Singapore6Nights7Days";
import Singapore6Nightss7Dayss from "containers/ListingDetailPage/listing-stay-detail/Singapore6Nightss7Dayss";
import Singapore7N8D from "containers/ListingDetailPage/listing-stay-detail/Singapore7N8D";

*/}





//import DubaiBlog from "containers/BlogPage/DubaiBlog";

//import SrilankaHome from "landing/SrilankaHome/SrilankaHome";
//import SingaporeMalaysia6N7D from "containers/ListingDetailPage/listing-stay-detail/SingaporeMalaysia6N7D";
//import SingaporeMalaysia6Nights7Days from "containers/ListingDetailPage/listing-stay-detail/SingaporeMalaysia6Nights7Days";
//import SingaporeMalaysia8N9D from "containers/ListingDetailPage/listing-stay-detail/SingaporeMalaysia8N9D";
//import SingaporeMalaysia8Nights9Days from "containers/ListingDetailPage/listing-stay-detail/SingaporeMalaysia8Nights9Days";
//import SingaporeMalaysia10N11D from "containers/ListingDetailPage/listing-stay-detail/SingaporeMalaysia10N11D";
//import SingaporeMalaysia12N13D from "containers/ListingDetailPage/listing-stay-detail/SingaporeMalaysia12N13D";
//import SingaporeMalaysia14N15D from "containers/ListingDetailPage/listing-stay-detail/SingaporeMalaysia14N15D";
//import Srilanka3N4D from "containers/ListingDetailPage/listing-stay-detail/Srilanka3N4D";
//import Srilanka4N5D from "containers/ListingDetailPage/listing-stay-detail/Srilanka4N5D";
//import Srilanka4Nights5Days from "containers/ListingDetailPage/listing-stay-detail/Srilanka4Nights5Days";
//import Srilanka4Nightss5Dayss from "containers/ListingDetailPage/listing-stay-detail/Srilanka4Nightss5Dayss";
//import Srilanka5N6D from "containers/ListingDetailPage/listing-stay-detail/Srilanka5N6D";
//import Srilanka6N7D from "containers/ListingDetailPage/listing-stay-detail/Srilanka6N7D";
//import Srilanka6Nights7Days from "containers/ListingDetailPage/listing-stay-detail/Srilanka6Nights7Days";
//import Srilanka6Nightss7Dayss from "containers/ListingDetailPage/listing-stay-detail/Srilanka6Nightss7Dayss";

//import Srilanka6Nightsss7Daysss from "containers/ListingDetailPage/listing-stay-detail/Srilanka6Nightsss7Daysss";
//import Srilanka7N8D from "containers/ListingDetailPage/listing-stay-detail/Srilanka7N8D";
//import Srilanka7Nights8Days from "containers/ListingDetailPage/listing-stay-detail/Srilanka7Nights8Days";
//import Srilanka7Nightss8Dayss from "containers/ListingDetailPage/listing-stay-detail/Srilanka7Nightss8Dayss";
//import Srilanka7Nightsss8Daysss from "containers/ListingDetailPage/listing-stay-detail/Srilanka7Nightsss8Daysss";
//import Srilanka8N9D from "containers/ListingDetailPage/listing-stay-detail/Srilanka8N9D";
//import Srilanka9Nights10Days from "containers/ListingDetailPage/listing-stay-detail/Srilanka9Nights10Days";
//import Srilanka9Nightss10Dayss from "containers/ListingDetailPage/listing-stay-detail/Srilanka9Nightss10Dayss";

//import Hotel1 from "landing/MaldivesHome/Hotel1";
//import MauritiusHome from "../landing/MauritiusHome/MauritiusHome";



export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/thanks", exact: true, component: PageThanks },
  { path: "/enquiry", exact: true, component: PageEnquiry },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  { path: "/home-3", component: PageHome3 },
  { path: "/wizard", component: Wizard },
  { path: "/hotel-filter", component: HotelFilter },
  // { path: "/hotel-detail-page", component: HotelDetailPage},
  //
  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  //{ path: "/dubai-4nights-5days-tour-package", component: ItineraryPage },
  //{ path: "/dubai-5nights-6days-tour-package", component: Dubai5N6D },
  //{ path: "/dubai-6nights-7days-tour-package", component: Dubai6N7D },
  //{ path: "/dubai-luxury-5nights-6days-tour-package", component: DubaiLuxury5N6D },
  //{ path: "/dubai-luxury-5nights-6days-tour-packages", component: DubaiLuxury6N7D },
  //{ path: "/dubai-luxury-7nights-8days-tour-package", component: DubaiLuxury7N8D },
  //{ path: "/dubai-premium-6nights-7days-tour-package", component: DubaiPremium6N7D },
  //{ path: "/dubai-premium-yas-island-tour-package", component: DubaiPremiumYas },
  //{ path: "/dubai-premium-lapita-6nights-7days-tour-package", component: DubaiPremiumLapita },
  //{ path: "/listing-stay-detailss", component: ItineraryMobile },
  //


  //visadetail
  { path: "/singapore-visa", component: SingaporeVisa },
  { path: "/malaysia-visa", component: MalaysiaVisa },
  { path: "/dubai-visa", component: DubaiVisa },

  //Bali
  // { path: "/bali-4nights-5days-tour-packages", component: Bali4N5D },
  // { path: "/bali-5nights-6days-tour-packages", component: Bali5N6D },
  // { path: "/bali-6nights-7days-tour-package", component: Bali6N7D },
  // { path: "/bali-6nightss-7dayss-tour-package", component: Bali6Nightss7Dayss },
  // { path: "/bali-7nightss-8dayss-tour-package", component: Bali7Nights8Days },
  // { path: "/bali-7nights-8days-tour-package", component: Bali7Nightss8Dayss },
  // { path: "/bali-7night-8day-tour-package", component: Bali7Night8Day },
  // { path: "/bali-7night-8day-tour-packages", component: Bali7N8D },
  // { path: "/bali-10nights-11days-tour-packages", component: Bali10N11D },
  { path: "/group-tour-packages", component: GroupTour },
  { path: "/maldives-tour-packages", component: MaldivesHome },
  { path: "/mauritius-tour-packages", component: MauritiusHome },

  { path: "/pagecomingsoon", component: Pagecomingsoon },


  //Cambodia
  //{ path: "/cambodia-3nights-4days-tour-package", component: Cambodia3N4D },
  //{ path: "/cambodia-4nights-5days-tour-package", component: Cambodia4N5D },
  //{ path: "/cambodia-5nights-6days-tour-package", component: Cambodia5N6D },


  //singapore
  //{ path: "/singapore-3nights-4days-tour-package", component: Singapore3N4D },
  //{ path: "/singapore-4nights-5days-tour-package", component: Singapore4N5D },
  //{ path: "/singapore-6nights-7days-tour-package", component: Singapore6N7D },
  //{ path: "/singapore-6nights-7days-tour-packages", component: Singapore6Nights7Days },
  //{ path: "/singapore-6nights-7days-tour-packagess", component: Singapore6Nightss7Dayss },
  //{ path: "/singapore-7nights-8days-tour-package", component: Singapore7N8D },

  //srilanka
  //{ path: "/srilanka-3nights-4days-tour-package", component: Srilanka3N4D },
  //{ path: "/srilanka-4nights-5days-tour-package", component: Srilanka4N5D },
  //{ path: "/srilanka-4nights-5days-tour-packages", component: Srilanka4Nights5Days },
  /// { path: "/srilanka-4nights-5days-tour-packagess", component: Srilanka4Nightss5Dayss },
  //{ path: "/srilanka-5nights-6days-tour-package", component: Srilanka5N6D },
  //{ path: "/srilanka-6nights-7days-tour-package", component: Srilanka6N7D },
  //{ path: "/srilanka-6nights-7days-tour-packages", component: Srilanka6Nights7Days },
  //{ path: "/srilanka-6nights-7days-tourr-packages", component: Srilanka6Nightss7Dayss },
  ///{ path: "/srilanka-6nights-7days-tour-packagess", component: Srilanka6Nightsss7Daysss },
  // { path: "/srilanka-7nights-8days-tour-package", component: Srilanka7N8D },
  //{ path: "/srilanka-7nights-8days-tour-packages", component: Srilanka7Nights8Days },
  //{ path: "/srilanka-7nights-8days-tour-packagess", component: Srilanka7Nightss8Dayss },
  //{ path: "/srilanka-7nights-8days-tour-packagesss", component: Srilanka7Nightsss8Daysss },
  //{ path: "/srilanka-8nights-9days-tour-package", component: Srilanka8N9D },
  //{ path: "/srilanka-9nights-10days-tour-package", component: Srilanka9Nights10Days },
  // { path: "/srilanka-9nights-10days-tour-packages", component: Srilanka9Nightss10Dayss },







  //combinmation 
  //{ path: "/singapore-malaysia-6nights-7days-tour-package", component: SingaporeMalaysia6N7D },
  //{ path: "/singapore-malaysia-6nights-7days-tour-packages", component: SingaporeMalaysia6Nights7Days },
  //{ path: "/singapore-malaysia-8nights-9days-tour-package", component: SingaporeMalaysia8N9D },
  //{ path: "/singapore-malaysia-8nights-9days-tour-packages", component: SingaporeMalaysia8Nights9Days },
  //{ path: "/singapore-malaysia-bangkok-10nights-11days-tour-package", component: SingaporeMalaysia10N11D },
  // { path: "/singapore-malaysia-bangkok-12nights-13days-tour-package", component: SingaporeMalaysia12N13D },
  // { path: "/singapore-malaysia-bangkok-14nights-15days-tour-package", component: SingaporeMalaysia14N15D },


  //Cambodia
  // { path: "/malaysia-3nights-4days-tour-package", component: Malaysia3N4D },
  //{ path: "/malaysia-7nights-8days-tour-package", component: Malaysia7N8D },
  // { path: "/malaysia-5nights-6days-tour-package", component: Malaysia5N6D },

  ///Vietnam
  //{ path: "/vietnam-3nights-4days-tour-package", component: Vietnam3N4D },
  //{ path: "/vietnam-4nights-5days-tour-package", component: Vietnam4N5D },
  //{ path: "/vietnam-5nights-6days-tour-package", component: Vietnam5N6D },
  //{ path: "/vietnam-5nights-6days-tour-packages", component: Vietnam5Nights6Days },
  //{ path: "/vietnam-9nights-10days-tour-package", component: Vietnam9N10D },
  //{ path: "/vietnam-cambodia-8nights-9days-tour-package", component: VietnamCambodia8N9D },
  //{ path: "/vietnam-cambodia-12nights-13days-tour-package", component: VietnamCambodia12N13D },

  //Thailand
  //{ path: "/thailand-4nights-5days-tour-package", component: Thailand4N5D },
  //{ path: "/thailand-6nights-7days-tour-package", component: Thailand6N7D },
  //{ path: "/thailand-10nights-11days-tour-package", component: Thailand10N11D },
  //{ path: "/thailand-3nights-4days-tour-package", component: Thailand3N4D },
  //{ path: "/thailand-6nights-7days-tour-packages", component: Thailand6Nights7Days },
  //{ path: "/thailand-8nights-9days-tour-package", component: Thailand8N9D },
  //{ path: "/thailand-4nights-5days-tour-packages", component: Thailand4Night5Day },
  //{ path: "/thailand-10nights-11days-tour-packages", component: Thailand10Nights11Days },
  //{ path: "/thailand-4nights-5days-tourr-packages", component: Thailand5Day },
  //{ path: "/thailand-4nights-5days-tour-packagess", component: Thailand4Nights5Dayss },

  //{ path: "/maldives-tour-packages", component: MaldivesHome },
  //{ path: "/mauritius-tour-packages", component: MauritiusHome },
  { path: "/hotel-1", component: Hotel1 },
  { path: "/hotel-2", component: Hotel2 },

  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  //{ path: "/listing-car", component: ListingCarPage },
  //{ path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/filter-details/", exact: true, component: ListingRealEstatePage },
  { path: "/filter-details/:place/", component: ListingRealEstatePage },
  { path: "/filter-details/:place/:month", component: ListingRealEstatePage },
  //
  { path: "/listing-flights", component: ListingFlightsPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/add-listing-1", component: PageAddListing1 },
  //{ path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  { path: "/add-listings-1", component: PageAddListings1 },
  { path: "/add-listings-2", component: PageAddListings2 },
  //
  { path: "/contact", component: PageContact },
  { path: "/about-us", component: PageAbout },
  { path: "/aboutt", component: PageAboutt },
  { path: "/signup", component: PageSignUp },
  // path: "/login", component: PageLogin ,
  { path: "/subscription", component: PageSubcription },

  //{ path: "/mobile-login", component: PageMobileLogin },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path: "/cancellations", component: Cancellations },
  //

  // { path: "/dubai-tour-packages", component: DubaiHome },
  // { path: "/bali-tour-packages", component: BaliHome },
  //{ path: "/malaysia-tour-packages", component: MalaysiaHome },
  //{ path: "/cambodia-tour-packages", component: CambodiaHome },
  //{ path: "/vietnam-tour-packages", component: VietnamHome },
  //{ path: "/philippines-home", component: PhilippinesHome },
  // { path: "/visa", component: Visa },
  //{ path: "/singapore-tour-packages", component: SingaporeHome },
  //{ path: "/srilanka-tour-packages", component: SrilankaHome },
  // { path: "/australia-home", component: AustraliaHome },
  //{ path: "/turkey-home", component: TurkeyHome },
  //{ path: "/thailand-tour-packages", component: ThailandHome },
  //{ path: "/Newzealand-home", component: NewzealandHome },
  { path: "/flight-home", component: FlightHome },
  { path: "/hotel-home", component: HotelHome },
  //{ path: "/maldives-tour-packages", component: MaldivesHome },

  { path: "/fascinating-fusion-of-tradition-and-modernity", component: DubaiBlog },
  { path: "/tourist-attractions-in-dubai", component: DubaiBlogg },
  { path: "/fascinating-facts", component: BlogDubai },
  { path: "/desert-safari-adventure-in-dubai", component: BlogDubaii },
  { path: "/popup", component: PagePopup },
  { path: "/experiences-tourist-often-miss-in-thailand", component: BlogThailand },
  { path: "/exploring-bali-culinary-delights", component: BlogBali },
  { path: "/island-paradise-waiting-to-be-explored", component: BlogMalaysia },
  { path: "/malaysia-adventure-from-rainforests-to-urban-delights", component: BlogMalaysiaa },
  { path: "/unveiling-the-smart-city-initiatives", component: BlogSingapore },
  { path: "/exploring-the-serenity-within-the-urban-bustle", component: BlogSingaporee },
  { path: "/romantic-gateway-guide-to-srilanka", component: BlogSrilanka },
  { path: "/blogs", component: Blog },
  { path: "/journey-through-ancient-temples-and-pagodas", component: BlogVietnam },
  { path: "/cambodia-lesser-known-religious-sites", component: BlogCambodia },
  
  { path: "/journey-through-temples-and-traditions", component: BlogNepal },
  { path: "/exploring-the-wonders-of-the-philippines", component: BlogPhilippines },
  { path: "/ancient-history-meets-modern-marvels", component: BlogGreece },
  { path: "/historic-landscapes-and-cultural-marvels", component: BlogEurope},
  { path: "/checkout-status", component: CheckoutStatus},
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay or asynchronous task
    const timer = setTimeout(() => {
      setLoading(false); // Hide the preloader
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
      <>
        <ScrollToTop />
        <SiteHeader />

        <Routes>
          {pages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
          <Route path="/:slug" element={<Dynamic_with_checkout />} />
          <Route element={<Page404 />} />
        </Routes>
        
        {WIN_WIDTH < 768 && <FooterNav />}
        <Footer />
      </>
    </BrowserRouter>
  );
};

export default MyRoutes;
