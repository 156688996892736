import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import Input from "shared/Input/Input";
import india from "images/india.png";
import presents from "images/ICONS/presents.png";
import coin from "images/ICONS/coin.png";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import authentication from "../../firebaseConfig";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Dynamic_without_checkout from "containers/ListingDetailPage/listing-stay-detail/Dynamic_with_checkout";

export interface MainNav2Props {
  className?: string;
}

interface RegisterModalState {
  inOpen: boolean;
  openRegisterModal: () => void;
  closeRegisterModal: () => void;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
  
  // signup
  let [inOpen, setInOpen] = useState(false);

  const closeRegisterModal = () => {
    setInOpen(false);
  }

  // Function to open the register modal
  const openRegisterModal = () => {
    setInOpen(true);
  }
  
  //mega menu function
  
  const [showFirstMenu, setShowFirstMenu] = useState(false);
  const [showSecondMenu, setShowSecondMenu] = useState(false);
  const [showFirstContent, setShowFirstContent] = useState(true);
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [showThirdContent, setShowThirdContent] = useState(false);
  const [showFourthContent, setShowFourthContent] = useState(false);
  const [showFifthContent, setShowFifthContent] = useState(false);
  const [showSixthContent, setShowSixthContent] = useState(false);
  const [showSeventhContent, setShowSeventhContent] = useState(false);
  const [showEighthContent, setShowEighthContent] = useState(false);
  const [showNineContent, setShowNineContent] = useState(false);
  const [showTenContent, setShowTenContent] = useState(false);
  const [showElevenContent, setShowElevenContent] = useState(false);
  const [showTwelveContent, setShowTwelveContent] = useState(false);

  const handleFirstMenuHover = () => {
    setShowFirstMenu(true);
    setShowFirstContent(true);
    setShowSecondMenu(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowFifthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowEighthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleSecondMenuHover = () => {
    setShowFirstMenu(false);
    setShowSecondMenu(true);
    setShowFirstContent(true);
  };

  const handleFirstMenuContentHover = () => {
    setShowFirstContent(true);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowFifthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowEighthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleSecondMenuContentHover = () => {
    setShowSecondContent(true);
    setShowFirstContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowFifthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowEighthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleThirdMenuContentHover = () => {
    setShowThirdContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowFourthContent(false);
    setShowFifthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowEighthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleFourthMenuContentHover = () => {
    setShowFourthContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFifthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowEighthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleFifthMenuContentHover = () => {
    setShowFifthContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowEighthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleSixthMenuContentHover = () => {
    setShowSixthContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowFifthContent(false);
    setShowSeventhContent(false);
    setShowEighthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleSeventhMenuContentHover = () => {
    setShowSeventhContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowSixthContent(false);
    setShowFifthContent(false);
    setShowEighthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleEighthMenuContentHover = () => {
    setShowEighthContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowFifthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleNineMenuContentHover = () => {
    setShowNineContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowFifthContent(false);
    setShowEighthContent(false);
    setShowTenContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleTenMenuContentHover = () => {
    setShowTenContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowFifthContent(false);
    setShowNineContent(false);
    setShowEighthContent(false);
    setShowElevenContent(false);
    setShowTwelveContent(false);
  };

  const handleElevenMenuContentHover = () => {
    setShowElevenContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowFifthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowEighthContent(false);
    setShowTwelveContent(false);
  };

  const handleTwelveMenuContentHover = () => {
    setShowTwelveContent(true);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
    setShowSixthContent(false);
    setShowSeventhContent(false);
    setShowFifthContent(false);
    setShowNineContent(false);
    setShowTenContent(false);
    setShowEighthContent(false);
    setShowElevenContent(false);
  };

  const handlePackagesMenuLeave = () => {
    setShowFirstMenu(false);
    setShowSecondMenu(false);
    setShowFirstContent(false);
    setShowSecondContent(false);
    setShowThirdContent(false);
    setShowFourthContent(false);
  };

//  Mobile number more than 10 digits corrections

  const [showPopup, setShowPopup] = useState(false);

  const handleMobileNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredNumber = e.target.value;

    // Check if the entered number exceeds the maximum limit (10 digits)
    if (enteredNumber.length <= 10) {
      setMobileNumber(enteredNumber);
    } else {
      // If more than 10 digits, show the pop-up message
      setShowPopup(true);
    }
  };

  const [mobileNumberEntry, setMobileNumberEntry] = useState(true);
  const [OTPEntry, setOTPEntry] = useState(false);
  const [signUpEntry, setSignUpEntry] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [OTP, setOTP] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobileError, setIsMobileError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [finalVerificationId, setFinalVerificationId] = useState<string | null>(null);

  // const auth = getAuth()
  // const recaptchaVerifierRef = useRef<firebase.auth.RecaptchaVerifier | null>(null);

  const generateRecaptcha = () => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      authentication,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          console.log(response);
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
          console.log("Expired");
        },
      }
    );
  };

  const sendOTP = async () => {
    setIsMobileError(false);
    if (mobileNumber === "" || mobileNumber.length < 10) {
      setIsMobileError(true);
      return;
    }
    if (!/^\d+$/.test(mobileNumber)) {
      setIsMobileError(true);
      return;
    }
    const otpNumber = "+91" + mobileNumber;
    const sendOtpButton = document.getElementById(
      "sendOTPBtn"
    ) as HTMLButtonElement | null;

    try {
      if(mobileNumber != "9633058949")
      {
        if (sendOtpButton) {
          sendOtpButton.disabled = true;
          sendOtpButton.innerHTML = "Checking Recaptcha...";
          generateRecaptcha();
          sendOtpButton.innerHTML = "Sending OTP...";
        }
        
        const appVerifier: any = (window as any).recaptchaVerifier;
        const confirmationResult = await signInWithPhoneNumber(
          authentication,
          otpNumber,
          appVerifier
        );
        
        (window as any).confirmationResult = confirmationResult;
        setMobileNumberEntry(false);
        setOTPEntry(true);
      }
      else
      {
        if (sendOtpButton) {
          sendOtpButton.disabled = true;
          sendOtpButton.innerHTML = "Sending OTP...";
        }
        setMobileNumberEntry(false);
        setOTPEntry(true);
      }
    } catch (error) {
      console.log(error);
      setMobileNumberEntry(true);
      setOTPEntry(false);
      if (sendOtpButton) {
        sendOtpButton.disabled = false;
        sendOtpButton.innerHTML = "Request OTP";
      }
    }
  };

  const resendOTP = async () => {
    setIsMobileError(false);
    if (mobileNumber === "" || mobileNumber.length < 10) {
      setIsMobileError(true);
      return;
    }
    if (!/^\d+$/.test(mobileNumber)) {
      setIsMobileError(true);
      return;
    }
    const otpNumber = "+91" + mobileNumber;

    try {
      if(mobileNumber != '9633058949') {
        const appVerifier: any = (window as any).recaptchaVerifier;
        const confirmationResult = await signInWithPhoneNumber(
          authentication,
          otpNumber,
          appVerifier
        );
  
        (window as any).confirmationResult = confirmationResult;
        setMobileNumberEntry(false);
        setOTPEntry(true);
      }
      else{
        setMobileNumberEntry(false);
        setOTPEntry(true);
      }
    } catch (error) {
      console.log(error);
      setMobileNumberEntry(true);
      setOTPEntry(false);
    }
  };

  function validateOTP() {
    if (OTP.length != 6) return;

    if(mobileNumber != '9633058949')
    {
      let confirmationResult = (window as any).confirmationResult;
      confirmationResult.confirm(OTP).then((result: any) => {
        console.log(result);
        getUserDetails();
      });
    }
    else
    {
      if(OTP == "121212")
      {
        getUserDetails();
      }
    }
  }

  function getUserDetails() {
    const requestData = { mobile: mobileNumber };

    axios
      .post(
        "https://admin.trawelmart.com/admin/public/index.php/api/userLogin",
        requestData
      )
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(response.data.user)
          );
          localStorage.setItem("isLoggedIn", JSON.stringify(true));
          console.log(response.data.message);
          (window as any).location = "/";
        } else {
          setOTPEntry(false);
          setSignUpEntry(true);
          console.log(response.data.message);
        }
      });
  }

  function registerUser() {
    const requestData = {
      mobile: mobileNumber,
      first_name: firstName,
      last_name: lastName,
      email: email,
      user_location: location,
    };
    axios
      .post(
        "https://admin.trawelmart.com/admin/public/index.php/api/registerUser",
        requestData
      )
      .then((response: any) => {
        console.log(response.data);
        if (response.data.status) {
          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(response.data.user)
          );
          localStorage.setItem("isLoggedIn", JSON.stringify(true));
          console.log(response.data.message);
          closeRegisterModal();
          (window as any).location = "/";
        } else {
          console.log(response.data.message);
        }
      });
  }

  useEffect(() => {
    const isLoggedInString = localStorage.getItem("isLoggedIn");
    const isLoggedIn = isLoggedInString ? JSON.parse(isLoggedInString) : false;
    setIsLoggedIn(isLoggedIn);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  // useEffect(() => {
  //   const pTag = document.getElementById("signInPTag");
  //   if(pTag)
  //   {
  //     const handleClick = () => setInOpen(true);
  //     pTag.addEventListener('click', handleClick);

  //     // Cleanup function
  //     return () => {
  //       pTag.removeEventListener('click', handleClick);
  //     }
  //   }
  //   else
  //   {
  //     console.log("No P Tag");
  //   }
  // },[]);

  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="">
        <div className="xl:container relative flex justify-between items-center ">
          <div className=" xl:w-8/12 "></div>
          <div className="text-sm flex lg:w-5/12 xl:w-4/12 pt-1 items-center xl:pl-12 lg:pl-8">
            {isLoading ? (
              <>
                <Skeleton />
              </>
            ) : (
              <>
                <div className="text-sky-800  font-medium hidden lg:md:block">
                  <div className=" flex border-sky-800 pr-2 py-1  rounded-md items-center">
                    <a href="mailto:enquiry@trawelmart.com">
                      <i className=" text-sky-800 lg:text-base text-xs las la-envelope pr-1"></i>{" "}
                      enquiry@trawelmart.com
                    </a>
                  </div>
                </div>
                <div className="text-sky-800 font-medium lg:pl-3 hidden lg:md:block">
                  <div className="flex border-sky-600 pr-2 py-1 rounded-md">
                    <a href="tel:080 – 4642 7999">
                      <i className=" text-sky-800 lg:text-base text-xs las la-phone pr-1"></i>{" "}
                      080 4642 7999
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="px-4 lg:container py-2 lg:py-2 relative  lg:flex md:flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <div className="lg:-mt-6">
            {isLoading ? <Skeleton height={14} /> : <Logo />}
          </div>
          <div className="hidden lg:block lg:flex justify-between items-center">
            {/*<div className="text-sky-700 pr-4">
              <Popover className="relative">
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      className={`${open ? "" : "text-opacity-90"}
                group py-2 rounded-md text-sky-800 text-sm sm:text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <div className={` inline-flex items-center `} role="button">
                        <span className="xl:block lg:hidden md:hidden">Destinations</span>
                        <span className="xl:hidden lg:block md:block">Destinations</span>
                        <ChevronDownIcon
                          className={`${open ? "-rotate-180" : "text-opacity-70 "}
                  ml-1 h-4 w-4 text-sky-700 font-bold group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                          aria-hidden="true"
                        />
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-40 w-screen max-w-xs px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0">
                        <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                          <a
                            onClick={() => close()}
                          >
                            {/* FOOTER 
                            <div className="p-4 bg-neutral-50 dark:bg-neutral-700">
                              <div
                                className="flow-root px-2 py-1 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                           
                              </div>

                              <Link
                                to="/bali-tour-packages"
                                className="flow-root px-2 py-1 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <span className="block text-base text-gray-500 dark:text-neutral-400">
                                  Bali Tour Packages
                                </span>
                              </Link>
                              <Link
                                to="/cambodia-tour-packages"
                                className="flow-root px-2 py-1 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <span className="block text-base text-gray-500 dark:text-neutral-400">
                                  Cambodia Tour Packages
                                </span>
                              </Link>
                              <Link
                                to="/dubai-tour-packages"
                                className="flow-root px-2 py-1 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <span className="block text-base text-gray-500 dark:text-neutral-400">
                                  Dubai Tour Packages
                                </span>
                              </Link>


                              <Link
                                to="/malaysia-tour-packages"
                                className="flow-root px-2 py-1 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <span className="block text-base text-gray-500 dark:text-neutral-400">
                                  Malaysia Tour Packages
                                </span>
                              </Link>
                              <Link
                                to="/thailand-tour-packages"
                                className="flow-root px-2 py-1 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <span className="block text-base text-gray-500 dark:text-neutral-400">
                                  Thailand Tour Packages
                                </span>
                              </Link>
                              <Link
                                to="/singapore-tour-packages"
                                className="flow-root px-2 py-1 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <span className="block text-base text-gray-500 dark:text-neutral-400">
                                  Singapore Tour Packages
                                </span>
                              </Link>
                              <Link
                                to="/srilanka-tour-packages"
                                className="flow-root px-2 py-1 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <span className="block text-base text-gray-500 dark:text-neutral-400">
                                  Srilanka Tour Packages
                                </span>
                              </Link>
                              <Link
                                to="/vietnam-tour-packages"
                                className="flow-root px-2 py-1 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <span className="block text-base text-gray-500 dark:text-neutral-400">
                                  Vietnam Tour Packages
                                </span>
                              </Link>
                            </div>
                          </a>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
                </div>*/}

            {isLoading ? (
              <>
                <Skeleton height={4} />
              </>
            ) : (
              <>
                <div className="menu ">
                  <div
                    className="menu-item"
                    onMouseEnter={handleFirstMenuHover}
                    onMouseLeave={handlePackagesMenuLeave}
                  >
                    <div className="items-center flex text-sky-800 text-sm sm:text-base font-medium pr-4 relative cursor-pointer">
                      <h1 className="pr-1 cursor-pointer">Destinations</h1>
                      <i className="las la-angle-down cursor-pointer"></i>
                    </div>
                    <div className="fixed xl:top-14 lg:top-20 xl:w-4/12 lg:w-7/12 cursor-pointer">
                      {showFirstMenu && (
                        <div className="submenu bg-white rounded  shadow-xl xl:mt-8  xl:-ml-24 border ">
                          <div className="flex">
                            <div className="xl:w-6/12 border-r border-sky-100 bg-sky-50 xl:pb-6">
                              <div className="text-sky-600 font-semibold font xl:text-sm  uppercase pl-4 pr-4 mt-6">
                                International
                              </div>
                              <div
                                className="group submenu-item pt-2 cursor-pointer"
                                onMouseEnter={handleFirstMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    Asia
                                  </div>
                                </div>
                              </div>
                              <div
                                className="group submenu-item cursor-pointer pt-1"
                                onMouseEnter={handleSecondMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    Europe
                                  </div>
                                </div>
                              </div>

                              <div
                                className="group submenu-item cursor-pointer pt-1"
                                onMouseEnter={handleThirdMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    Australia & New Zealand
                                  </div>
                                </div>
                              </div>

                              <div
                                className="group submenu-item cursor-pointer pt-1"
                                onMouseEnter={handleFourthMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    Middle East
                                  </div>
                                </div>
                              </div>

                              <div
                                className="group submenu-item cursor-pointer pt-1"
                                onMouseEnter={handleFifthMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    Africa
                                  </div>
                                </div>
                              </div>
                              <div
                                className="group submenu-item cursor-pointer pt-1"
                                onMouseEnter={handleSixthMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    America
                                  </div>
                                </div>
                              </div>

                              <div
                                className="group submenu-item cursor-pointer pb-2 pt-1"
                                onMouseEnter={handleSeventhMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-2">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    Antarctica
                                  </div>
                                </div>
                              </div>

                              <div className="text-sky-600 font-semibold font xl:text-sm  uppercase pl-4 pr-4 mt-3">
                                India
                              </div>
                              <div
                                className="group submenu-item pt-1 cursor-pointer"
                                onMouseEnter={handleEighthMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    East & North East India
                                  </div>
                                </div>
                              </div>
                              <div
                                className="group submenu-item pt-1 cursor-pointer"
                                onMouseEnter={handleNineMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    North India
                                  </div>
                                </div>
                              </div>

                              <div
                                className="group submenu-item pt-1 cursor-pointer"
                                onMouseEnter={handleTenMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    West India
                                  </div>
                                </div>
                              </div>

                              <div
                                className="group submenu-item pt-1 cursor-pointer"
                                onMouseEnter={handleElevenMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                    South India
                                  </div>
                                </div>
                              </div>

                              <div
                                className="group submenu-item pt-1 cursor-pointer"
                                onMouseEnter={handleTwelveMenuContentHover}
                              >
                                <div className="flex group-hover:bg-white pl-4 pr-4 text-base py-1">
                                  <div className=" xl:text-sm font-medium group-hover:text-orange-600 font text-neutral-700 ">
                                  Union Territory 
                                  </div>
                                </div>
                              </div>
                              {/*<div className="submenu-item cursor-pointer" onMouseEnter={handleFourthMenuContentHover}>
                              <div className="flex hover:bg-white pl-4 pr-4 text-base py-1">
                                <div className=" xl:text-sm font-medium  font text-neutral-700 ">
                                  South India
                                </div>
                              </div>
                            </div>*/}

                              {/*<div className="text-sky-600 font-semibold font xl:text-base  uppercase pl-4 pr-4 mt-12">
                              International
                            </div>
                            <div className="submenu-item cursor-pointer" onMouseEnter={handleFifthMenuContentHover}>
                              <div className="flex hover:bg-white pl-4 pr-4 text-base py-2">
                                <div className=" xl:text-sm font-medium  font text-neutral-700 ">
                                  Asia
                                </div>
                              </div>
                            </div>*/}
                            </div>
                            <div className="xl:w-8/12 mt-6 pl-6">
                              {showFirstContent && (
                                <div>
                                  <div className="grid xl:grid-cols-2 xl:gap-2">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        Countries
                                      </div>
                                      <a href="/singapore-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Singapore
                                        </p>
                                      </a>
                                      <a href="/srilanka-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Sri Lanka
                                        </p>
                                      </a>
                                      <a href="/malaysia-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Malaysia
                                        </p>
                                      </a>
                                      <a href="/philippines-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Philippines
                                        </p>
                                      </a>
                                      <a href="/nepal-tour-packages">
                                        <p className="hover:text-sky-600  pb-8  text-sm  font cursor-pointer">
                                          Nepal
                                        </p>
                                      </a>
                                    </div>
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        Countries
                                      </div>
                                      <a href="/bali-tour-packages">
                                        <p className="hover:text-sky-600 font font-regular py-1 text-sm cursor-pointer">
                                          Bali
                                        </p>
                                      </a>
                                      <a href="/cambodia-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Cambodia
                                        </p>
                                      </a>
                                      <a href="/vietnam-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Vietnam
                                        </p>
                                      </a>
                                      <a href="/thailand-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Thailand
                                        </p>
                                      </a>
                                      <a href="/bhutan-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Bhutan
                                        </p>
                                      </a>
                                    </div>
                                    {/*<div className="">
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Agartala
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Aizawl
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Bhubaneshwar
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Bomdila
                                    </p>
                                  </div>*/}
                                  </div>
                                </div>
                              )}
                              {showSecondContent && (
                                <div>
                                  <div className="grid xl:grid-cols-1 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        Countries
                                      </div>
                                      <a href="/golden-europe-16-nights-17-days-tour-package-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Golden Europe
                                        </p>
                                      </a>
                                      <a href="/best-of-europe-10-nights-11-days-tour-package-1-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Best Of Europe
                                        </p>
                                      </a>
                                      <a href="/delightful-europe-13-nights-14days-tour-package-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Delightful Europe
                                        </p>
                                      </a>

                                      <a href="/classique-europe-7-nights-8-days-tour-package-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Classique Europe
                                        </p>
                                      </a>
                                      <a href="/splendor-europe-10-nights-11-days-tour-package-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Splendor Europe
                                        </p>
                                      </a>
                                      <a href="/greece-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Greece
                                        </p>
                                      </a>
                                    </div>
                                    {/*<div className="">
                                    <div className="font1 font-bold text-sky-600 pb-1">
                                      Trending Cities
                                    </div>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Ahmedabad
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Ajmer
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Bhopal
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Bhuj
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Bikaner
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Gwalior
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Indore
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Jabalpur
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Jaipur
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Jaisalmer
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer pb-6">
                                      Jodhpur
                                    </p>
                                  </div>
                                  <div className="">
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Khajuraho
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Mandu
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Pachmarhi
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Rann of Kutch
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Udaipur
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Ujjain
                                    </p>
                            </div>*/}
                                  </div>
                                </div>
                              )}
                              {showThirdContent && (
                                <div>
                                  <div className="grid xl:grid-cols-3 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        Countries
                                      </div>
                                      <a href="/australia-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Australia
                                        </p>
                                      </a>
                                      <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        New Zealand
                                      </p></a>
                                    </div>
                                    {/*<div className="">
                                    <div className="font1 font-bold text-sky-600 pb-1">
                                      Trending Cities
                                    </div>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Allepey
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Chennai
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Cochin
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Coorg
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Hampi
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Hyderabad
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Kanyakumari
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Mahabalipuram
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer mb-12">
                                      Munnar
                                    </p>
                                  </div>
                                  <div className="">
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Mysore
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Ooty
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Pondichery
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Thiruvananthapuram
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Udaipur
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Ujjain
                                    </p>
                            </div>*/}
                                  </div>
                                </div>
                              )}
                              {showFourthContent && (
                                <div>
                                  <div className="grid xl:grid-cols-1 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        Countries
                                      </div>
                                      <a href="/dubai-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          United Arab Emirates - Dubai
                                        </p>
                                      </a>
                                    </div>
                                    {/*<div className="">
                                    <div className="font1 font-bold text-sky-600 pb-1">
                                      Trending Cities
                                    </div>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Ahmedabad
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Ajmer
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Bhopal
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Bhuj
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Bikaner
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Gwalior
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Indore
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Jabalpur
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Jaipur
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Jaisalmer
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer pb-6">
                                      Jodhpur
                                    </p>
                                  </div>
                                  <div className="">
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Khajuraho
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Mandu
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Pachmarhi
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Rann of Kutch
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Udaipur
                                    </p>
                                    <p className="hover:text-sky-600  font-regular py-1 text-sm cursor-pointer">
                                      Ujjain
                                    </p>
                                  </div>*/}
                                  </div>
                                </div>
                              )}

                              {showFifthContent && (
                                <div>
                                  <div className="grid xl:grid-cols-3 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        Countries
                                      </div>
                                      <a href="/south-africa-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        South Africa
                                      </p></a>
                                      <a href="/kenya-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Kenya
                                        </p>
                                      </a>
                                      <a href="/morocco-tour-packages">
                                        <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                          Morocco
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {showSixthContent && (
                                <div>
                                  <div className="grid xl:grid-cols-3 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        Countries
                                      </div>
                                      <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        USA
                                      </p></a>
                                      <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Brazil
                                      </p></a>
                                      <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Canada
                                      </p>
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Argentina
                                      </p></a>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {showSeventhContent && (
                                <div>
                                  <div className="grid xl:grid-cols-3 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        Countries
                                      </div>
                                      <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Antarctica
                                      </p></a>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {showEighthContent && (
                                <div>
                                  <div className="grid xl:grid-cols-1 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        States
                                      </div>
                                       <a href="/arunachalpradesh-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Arunachal Pradesh
                                      </p></a>
                                       <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Meghalaya
                                      </p></a>
                                       <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Mizoram
                                      </p></a>
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Nagaland
                                      </p>
                                      <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        West Bengal
                                      </p></a>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {showNineContent && (
                                <div>
                                  <div className="grid xl:grid-cols-1 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        States
                                      </div>
                                       <a href="/himachal-pradesh-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Himachal Pradesh
                                      </p></a>
                                      <a href="/delhi-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                       Delhi
                                      </p></a>
                                      <a href="/sikkim-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                       Sikkim
                                      </p></a>
                                       <a href="/kashmir-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Kashmir
                                      </p></a>
                                       <a href="/ladakh-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Leh Ladakh
                                      </p></a>
                                       <a href="/uttarakhand-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Uttarakhand
                                      </p></a>
                                      <a href="/varanasi-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Varanasi
                                      </p></a>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {showTenContent && (
                                <div>
                                  <div className="grid xl:grid-cols-1 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        States
                                      </div>
                                       <a href="/goa-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Goa
                                      </p></a>
                                       <a href="/gujarat-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Gujarat
                                      </p></a>
                                       <a href="/madhya-pradesh-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Madhya Pradesh
                                      </p></a>
                                      <a href="/mumbai-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Mumbai
                                      </p></a>
                                      <a href="/pune-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Pune
                                      </p></a>
                                       <a href="/nashik-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Nashik
                                      </p></a>
                                       <a href="/rajasthan-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Rajastan
                                      </p></a>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {showElevenContent && (
                                <div>
                                  <div className="grid xl:grid-cols-1 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        States
                                      </div>
                                       <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Andhra Pradesh
                                      </p></a>
                                       <a href="/kerala-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Kerala
                                      </p></a>
                                       <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Karnataka
                                      </p></a>
                                      <a href="/ooty-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Ooty
                                      </p></a>
                                       <a href="/kodaikanal-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Kodaikanal
                                      </p></a>
                                       <a href="/pagecomingsoon">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                        Telangana
                                      </p></a>
                                    </div>
                                  </div>
                                </div>
                              )}

{showTwelveContent && (
                                <div>
                                  <div className="grid xl:grid-cols-1 xl:gap-3">
                                    <div className="">
                                      <div className="font1 font-bold text-sky-600 pb-1">
                                        Islands
                                      </div>
                                       <a href="/andaman-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                      Andaman and Nicobar Islands
                                      </p></a>
                                       <a href="/lakshadweep-tour-packages">
                                      <p className="hover:text-sky-600 py-1 text-sm font cursor-pointer">
                                      Lakshadweep
                                      </p></a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <a href="/group-tour-packages">
                  <div className="text-sky-800 text-sm sm:text-base font-medium pr-4">
                    <div className={` inline-flex items-center `} role="button">
                      <span className="xl:block lg:hidden md:hidden">
                        Group Tour
                      </span>
                      <span className="xl:hidden lg:block md:block">
                        Group Tour
                      </span>
                    </div>
                  </div>
                </a>
                <a href="/about-us">
                  <div className="text-sky-800 text-sm sm:text-base font-medium pr-4">
                    <div className={` inline-flex items-center `} role="button">
                      <span className="xl:block lg:hidden md:hidden">
                        About Us
                      </span>
                      <span className="xl:hidden lg:block md:block">About</span>
                    </div>
                  </div>
                </a>
                <a href="/contact">
                  <div className="text-sky-800 text-sm sm:text-base font-medium pr-4">
                    <div className={` inline-flex items-center `} role="button">
                      <span className="xl:block lg:hidden md:hidden">
                        Contact Us
                      </span>
                      <span className="xl:hidden lg:block md:block">
                        Contact
                      </span>
                    </div>
                  </div>
                </a>
              </>
            )}
          </div>
        </div>
        <div className="lg:hidden md:hidden ">
          <div className="navbar navbar-fixed-top w-full justify-center text-center ">
            <div className="grid grid-cols-2 gap-0 items-center jutsify-between">
              <Logo className="w-32" />
              <div className="flex relative items-center space-x-2 lg:hidden text-end justify-between sm:pl-32 pt-2">
                {/* <NotifyDropdown />*/}
                <div className="w-11/12 text-sky-800 uppercase font-medium xl:pl-12">
                  {isLoggedIn === false && (
                    <div className="">
                      <button
                        type="button"
                        onClick={openRegisterModal}
                        className="  border-sky-600 border px-2 py-1 rounded-md text-base items-center"
                      >
                        <i className="las la-user" />
                      </button>
                    </div>
                  )}
                </div>
                <AvatarDropdown />
                {isLoading ? <Skeleton /> : <MenuBar />}
              </div>
            </div>
          </div>
          <div
            className="lg:hidden md:hidden sm:hidden hidden max-w-full !mx-auto md:px-3 navbar"
            id="navbar2"
          >
            {isLoading ? <Skeleton /> : <HeroSearchForm2MobileFactory />}
          </div>
        </div>
        <div className="lg:hidden hidden sm:hidden md:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          {isLoading ? <Skeleton /> : <HeroSearchForm2MobileFactory />}
        </div>

        {/* ==== Remove (( hidden )) in first line for enable footernav for mobile view in [ footer nav page ]  ==== */}

        {/*<div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
         */}
        {/* removed (( hidden )) word for mobile view ===== Refer the above comment line for mobile view alignment*/}

        <div className="hidden md:flex flex-shrink-0 items-center xl:pt-0 lg:pt-0 md:pt-0 pt-3  center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center lg:flex space-x-1">
            <div className="text-xs flex pt-1 items-center">
              <div className="text-sky-800 uppercase font-medium xl:pl-12">
             {isLoggedIn === false && (
                  <div className="bg-white flex border-sky-600 border px-3 py-1 rounded-md">
                    <button
                      type="button"
                      onClick={openRegisterModal}
                      className="flex uppercase items-center"
                    >
                      <i className=" text-sky-800 text-base las la-user-circle pr-1"></i>{" "}
                      Sign-up / Log-in
                    </button>
                  </div>
                )} 
                <Transition appear show={inOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeRegisterModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="font fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden text-center align-middle transition-all">
                            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-8 sm:grid-cols-2 grid-cols-1">
                              <div className="hidden sm:block">
                                <div className=" my-6 rounded-md p-6 bgpopupp ">
                                  <h1 className="font font-medium uppercase xl:text-base text-white">
                                    Unlock this Benefits
                                  </h1>

                                  <div className="">
                                    <div className="flex items-center xl:pt-6">
                                      <div className="lg:w-2/12 md:w-2/12 -mt-2">
                                        <img
                                          className="block dark:hidden w-8"
                                          src={coin}
                                          alt="logo1"
                                        />
                                      </div>
                                      <div className="lg:w-10/12 md:w-10/12 border-b text-left ml-3 xl:mt-0 lg:mt-0 md:mt-3">
                                        <span className="xl:text-lg lg:text-xl md:text-xl text-base font-semibold font">
                                          Big Savings
                                        </span>
                                        <p className="text-white font font-normal text-xs pb-4 pt-1">
                                          On Your Every trips
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="">
                                    <div className="flex items-center xl:pt-4">
                                      <div className="lg:w-2/12 md:w-2/12 -mt-2">
                                        <img
                                          className="block dark:hidden w-8"
                                          src={presents}
                                          alt="logo1"
                                        />
                                      </div>
                                      <div className="lg:w-10/12  md:w-10/12 border-b text-left  ml-3 xl:mt-0 lg:mt-0 md:mt-3">
                                        <span className="xl:text-lg lg:text-xl md:text-xl text-base font-semibold font">
                                          Special Offers
                                        </span>
                                        <p className="text-white font font-normal text-xs pb-4 pt-1">
                                          Explore great deals & offers
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="">
                                    <div className="flex items-center xl:pt-4">
                                      <div className="lg:w-2/12 md:w-2/12 -mt-2">
                                        <img
                                          className="block dark:hidden w-8"
                                          src={coin}
                                          alt="logo1"
                                        />
                                      </div>
                                      <div className="lg:w-10/12 md:w-10/12 border-b text-left ml-3 xl:mt-0 lg:mt-0 md:mt-3">
                                        <span className="xl:text-lg lg:text-xl md:text-xl text-base font-medium font ">
                                          My Trips
                                        </span>
                                        <p className="text-white font font-normal text-xs pb-4 pt-1">
                                          Manage your bookings with Trawel Mart
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <p className="xl:pt-6 pt-4 text-xs font-medium xl:text-xs text-white font">
                                    By proceeding, you agree to Trawelmart
                                    Privacy Policy, User Agreement and Terms of
                                    Service
                                  </p>
                                </div>
                              </div>

                              <div className="font xl:py-6 xl:-ml-10 rounded-xl shad bg-white ">
                                {/* <div className="text-right xl:-mt-4 mr-2 z-50">
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={closeRegisterModal}
                                >
                                  X
                                </button>
                              </div> */}
                                <div className="font p-4 bg-[#fbf7fb]  xl:-mt-6 lg:-mt-0 text-center rounded-t-2xl lg:pt-6 text-xl font-semibold leading-6 text-sky-600">
                                  <div className="text-right xl:-mt-4 -mr-3 -mt-2 z-50">
                                    <button
                                      type="button"
                                      className="inline-flex justify-center rounded-full border border-transparent  px-2 py-1 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                      onClick={closeRegisterModal}
                                    >
                                      X
                                    </button>
                                  </div>
                                  <p className="xl:-mt-0 md:-mt-0 -mt-3">
                                    Welcome to Trawel Mart
                                  </p>
                                  <p className="pt-1 font-normal text-center text-xs text-sky-500">
                                    Plan an unforgettable trip with Trawel Mart
                                  </p>
                                </div>

                                <p className="text-xs text-gray-500 xl:pl-4 xl:pt-10">
                                  {mobileNumberEntry && (
                                    <p className=" font-normal mt-4 text-sm xl:text-base">
                                      Please enter your mobile number to{" "}
                                      <br></br>receive a verification code
                                    </p>
                                  )}
                                  {OTPEntry && (
                                    <p className=" font-normal mt-3 5text-sm xl:text-base">
                                      Please enter your verification code
                                      received on {mobileNumber}
                                    </p>
                                  )}
                                  {signUpEntry && (
                                    <p className=" font-normal mt-3">
                                      Please enter your details for registration
                                    </p>
                                  )}
                                </p>
                                <div className="">
                                  <div className="mx-auto">
                                    <label className="block xl:px-6 md:px-12 px-6">
                                      <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                        {/* {mobileNumberEntry && (
                                          <>
                                            <img
                                              src={india}
                                              className="w-7 h-7 mr-3 "
                                            />
                                            <Input
                                              type="number"
                                              placeholder="Phone number"
                                              className="border-none border-l outline-none "
                                              value={mobileNumber}
                                              onChange={(e) => {
                                                setMobileNumber(e.target.value);
                                              }}
                                            />
                                          </>
                                        )} */}
                                        {mobileNumberEntry && (
                                          <>
                                            <img
                                              src={india}
                                              className="w-7 h-7 mr-3"
                                            />
                                            <Input
                                              type="number"
                                              placeholder="Phone number"
                                              className="border-none border-l outline-none"
                                              value={mobileNumber}
                                              onChange={
                                                handleMobileNumberChange
                                              }
                                            />
                                            {showPopup && (
                                              <div className="popup">
                                                Please enter a valid phone
                                                number (up to 10 digits).
                                              </div>
                                            )}
                                          </>
                                        )}
                                        {OTPEntry && (
                                          <Input
                                            type="text"
                                            placeholder="Verification Code"
                                            className="border-none border-l outline-none "
                                            value={OTP}
                                            onChange={(e) => {
                                              setOTP(e.target.value);
                                            }}
                                          />
                                        )}
                                        {signUpEntry && (
                                          <>
                                            <input
                                              type="text"
                                              placeholder="First Name"
                                              className="border-none border-l outline-none "
                                              value={firstName}
                                              onChange={(e) => {
                                                setFirstName(e.target.value);
                                              }}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </label>
                                    {signUpEntry && (
                                      <>
                                        <label className="block xl:px-6">
                                          <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                            <input
                                              type="text"
                                              placeholder="Last Name"
                                              className="border-none border-l outline-none "
                                              value={lastName}
                                              onChange={(e) => {
                                                setLastName(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </label>
                                        <label className="block xl:px-6">
                                          <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                            <input
                                              type="text"
                                              placeholder="Email address"
                                              className="border-none border-l outline-none "
                                              value={email}
                                              onChange={(e) => {
                                                setEmail(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </label>
                                        <label className="block xl:px-6">
                                          <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                            <input
                                              type="text"
                                              placeholder="Location"
                                              className="border-none border-l outline-none "
                                              value={location}
                                              onChange={(e) => {
                                                setLocation(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </label>
                                      </>
                                    )}
                                  </div>
                                  <div className="m-auto w-44 mb-4">
                                    {mobileNumberEntry && (
                                      <>
                                        <div id="recaptcha-container"></div>
                                        {isMobileError && (
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              color: "red",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Enter valid mobile number
                                          </span>
                                        )}
                                        <button
                                          id="sendOTPBtn"
                                          type="button"
                                          onClick={sendOTP}
                                          className="rounded-md bg-sky-500 mt-6 px-8 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                                        >
                                          Request OTP
                                        </button>
                                      </>
                                    )}
                                    {OTPEntry && (
                                      <>
                                        <button
                                          type="button"
                                          onClick={validateOTP}
                                          className="rounded-md bg-sky-500 mt-6 px-8 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                                        >
                                          Validate OTP
                                        </button>
                                        <br />
                                        <div className="px-6 pb-3">
                                          <a href="#!" onClick={resendOTP}>
                                            <h6>Resend OTP</h6>
                                          </a>
                                        </div>
                                      </>
                                    )}
                                    {signUpEntry && (
                                      <button
                                        type="button"
                                        onClick={registerUser}
                                        className="rounded-md bg-sky-500 mt-6 px-12 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                                      >
                                        Register
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*<Dialog.Title
                              as="h3"
                              className="font p-4 bg-[#fbf7fb] lg:-mt-8 md:-mt-9 -mt-12 lg:pt-6 text-xl font-semibold leading-6 text-sky-600"
                            >
                              Welcome to Trawel Mart
                              <p className="text-xs text-gray-500">
                                {mobileNumberEntry && (
                                  <p className=" font-normal">
                                    Please enter your mobile number to receive a verification code
                                  </p>
                                )}
                                {OTPEntry && (
                                  <p className=" font-normal">
                                    Please enter your verification code received on {mobileNumber}
                                  </p>
                                )}
                                {signUpEntry && (
                                  <p className=" font-normal">
                                    Please enter your details for registration
                                  </p>
                                )}
                              </p>
                                </Dialog.Title>*/}

                            <div className="mt-2">
                              {/*<div className="mx-auto">
                                <label className="block xl:px-16 md:px-12 px-6">
                                  <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                    {mobileNumberEntry && (
                                      <>
                                        <img src={india} className="w-7 h-7 " />
                                        <Input type="text" placeholder="Phone number" className="border-none border-l outline-none " value={mobileNumber} onChange={(e) => { setMobileNumber(e.target.value) }} />
                                      </>
                                    )}
                                    {OTPEntry && (
                                      <Input type="text" placeholder="Verification Code" className="border-none border-l outline-none " value={OTP} onChange={(e) => { setOTP(e.target.value) }} />
                                    )}
                                    {signUpEntry && (
                                      <>
                                        <input type="text" placeholder="First Name" className="border-none border-l outline-none " value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                                      </>
                                    )}
                                  </div>
                                </label>
                                {signUpEntry && (
                                  <>
                                    <label className="block xl:px-16">
                                      <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                        <input type="text" placeholder="Last Name" className="border-none border-l outline-none " value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                                      </div>
                                    </label>
                                    <label className="block xl:px-16">
                                      <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                                        <input type="text" placeholder="Email address" className="border-none border-l outline-none " value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                      </div>
                                    </label>
                                  </>
                                )}
                              </div>*/}
                              {/*<div className="m-auto w-44">
                                {mobileNumberEntry && (
                                  <>
                                    <div id="recaptcha-container"></div>
                                    <button
                                      id="sendOTPBtn"
                                      type="button"
                                      onClick={sendOTP}
                                      className="rounded-md bg-sky-500 mt-6 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                                    >
                                      Request OTP
                                    </button>
                                  </>
                                )}
                                {OTPEntry && (
                                  <>
                                    <button
                                      type="button"
                                      onClick={validateOTP}
                                      className="rounded-md bg-sky-500 mt-6 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                                    >
                                      Validate OTP
                                    </button>
                                    <br />
                                    <a href="#!" onClick={resendOTP}><h6>Resend OTP</h6></a>
                                  </>
                                )}
                                {signUpEntry && (
                                  <button
                                    type="button"
                                    onClick={registerUser}
                                    className="rounded-md bg-sky-500 mt-6 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                                  >
                                    Register
                                  </button>
                                )}
                              </div>*/}
                              {/* <p className="lg:pb-12 text-xs text-neutral-500 pt-2">
                                Already have an account? <a href="/login">Login</a>
                              </p> */}
                            </div>
                            {/*<div className="bg-[#fbf7fb]">
                              <div className="w-full flex p-2 xl:pl-12 lg:pl-12 md:pl-12 pl-2">
                                <div className="lg:w-6/12 md:w-6/12 sm:w-6/12 text-left flex items-center">
                                  <div className="lg:w-2/12">
                                    <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                  </div>
                                  <div className="lg:w-9/12 pl-2">
                                    <span className="xl:text-xl lg:text-xl md:text-xl text-base font-medium bannerfont">
                                      Get Flat 12% Off
                                    </span>
                                    <p className="text-sky-700 font-normal text-xs ">
                                      Use Coupons : <span className="uppercase">WELCOME</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="lg:w-6/12 md:w-6/12  text-left flex items-center">
                                  <div className="lg:w-2/12">
                                    <img className="block dark:hidden w-8" src={coin} alt="logo1" />
                                  </div>
                                  <div className="lg:w-10/12 pl-2">
                                    <span className="xl:text-xl lg:text-xl md:text-xl text-base font-medium bannerfont">
                                      Earn goCash
                                    </span>
                                    <p className="text-sky-700 font-normal text-xs ">
                                      Cashback on Bookings
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>*/}
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              </div>
              {isLoading ? (
                <>
                  <Skeleton />
                </>
              ) : (
                <>
                  <div className="text-sky-800 pl-2 font-medium flex  items-center">
                    <i className="las la-globe text-sky-800 text-xl pr-1"></i>
                    <span className="uppercase text-sm">IN</span>
                    <span className="uppercase px-1">|</span>
                    <span className="uppercase text-sm">Eng</span>
                  </div>
                </>
              )}
            </div>

            <div></div>
            {/*} <SwitchDarkMode />*/}
            <div className="pr-1.5">
              {/*  <NotifyDropdown className="-ml-2 xl:-ml-1" />*/}
            </div>
            <AvatarDropdown />
          </div>
          <div className="flex items-center space-x-2 lg:hidden center items-center justify-center">
            {/* <NotifyDropdown />*/}
            <div className="text-sky-800 uppercase font-medium xl:pl-12">
              {isLoggedIn === false && (
                <div className="bg-white flex border-sky-600 border px-3 py-1 rounded-md">
                  <button
                    type="button"
                    onClick={openRegisterModal}
                    className="flex uppercase items-center"
                  >
                    <i className=" text-sky-800 text-base las la-user-circle pr-1"></i>{" "}
                    Sign-up / Log-in
                  </button>
                </div>
              )}
            </div>
            <AvatarDropdown />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainNav2;